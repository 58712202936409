<template>
  <div v-if="order" class="container container-cart">
    <div class="row p-4 form-section">
      <div class="col-12 text-ftm-1 text-center">
        {{pageTitle}}
      </div>
    </div>

    <!-- MY ORDER -->
    <div v-if="step == 'cart-content'" class="row p-2 justify-content-center">
      <div class="accordion" id="accordionValidated">
        <div class="card">
          <div class="card-header section-cart-products" id="headingValidated">
            <h2 class="mb-0">
              <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseValidated" aria-expanded="false" aria-controls="collapseValidated">
                Voir / Modifier mon panier
              </button>
            </h2>
          </div>

          <div id="collapseValidated" class="collapse" aria-labelledby="headingValidated" data-parent="#accordionValidated">
            <div class="card-body" v-if="likes">
              <div class="d-flex flex-col flex-wrap justify-content-around">
                <div class="order-item-container" v-for="item in cartBoxes" v-bind:key="item.id">
                  <h-cart-box-item :key="item.id" :box="item.box" :quantity="item.quantity" :oneShot="item.oneShot" />
                </div>
                <div class="order-item-container"
                  v-for="item in cartProducts"
                  v-bind:key="item.id"
                >
                  <h-cart-item :item="item" :likes="likes" />
                </div>
                <order-box-item v-bind:box="box" v-for="box in orderBoxes" :key="`obi-${box.id}-${box.quantity}`"/>
                <order-item v-bind:item="item" :likes="likes" v-for="item in orderProducts" v-bind:key="item.id"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 section-simple">
        <div class="row row-payment border-bottom">
          <div class="col-8">
            <span>Total de la commande</span>
          </div>
          <div class="col-4 text-right">
            <span class="amount">{{orderTTC | formatIntAmount}}</span>
            <span class="currency">FCFA</span>
          </div>
        </div>
      </div>

      <div class="accordion accordion-glass" id="accordionGlass">
        <div class="card">
          <div class="card-header section-cart-products" id="headingGlass">
            <h2 class="mb-0">
              <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseGlass" aria-expanded="false" aria-controls="collapseGlass">
                <div class="row card-header-container">
                  <div class="col-8">
                    Valeur des consignes
                  </div>
                  <div class="col-4 text-right">
                    <span class="amount">{{glassAmount | formatIntAmount}}</span>
                    <span class="currency">FCFA</span>
                    <span class="show-more"></span>
                  </div>
                </div>
              </button>
            </h2>
          </div>

          <div id="collapseGlass" class="collapse" aria-labelledby="headingGlass" data-parent="#accordionGlass">
            <div class="card-body" v-if="likes">
              <div v-if="cartConsignes" class="d-flex flex-col flex-wrap justify-content-around">
                <glass-item v-for="cs in cartConsignes" v-bind:key="`${cs.id}-${cs.quantity}`" v-bind:consigne="cs" v-show="cs.quantity > 0" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row row-payment row-action align-content-center">
        <div class="col-12 justify-content-end align-content-center">
          <button v-if="emptyCart" type="button" @click="confirmEmpty()" class="btn btn-lg btn-secondary">Retour aux courses</button>
          <button v-if="!emptyCart" type="button" @click="gotoSelectDate()" class="btn btn-lg btn-primary">Suivant</button>
        </div>
      </div>

    </div>

    <div v-if="step == 'select-date'" class="row p-2 form-section">
      <section class="container-fluid delivery-date">
        <div v-if="order" class="row justify-content-center pt-2">
          <div class="col-10">
            <VueSlickCarousel ref="carousel"
              v-if="deliveryDates.length"  v-bind="slickSettings">
              <div :class="{ 'day-item-active': aDate.selected, 'day-item-today': aDate.today}" class="day-item" v-for="aDate in deliveryDates" v-bind:key="aDate.index">
                <div class="day-item-header text-center">
                  <img v-show="aDate.selected" :src="require('images/truck.png')" />
                  <img v-show="aDate.today" :src="require('images/delivery_cart.png')" />
                </div>
                <div class="day-item-body" @click="updateDate(aDate)">
                  <div class="row justify-content-center day">{{aDate.date | formatDay}}</div>
                  <div class="row justify-content-center month">{{aDate.date | formatMonth}}</div>
                  <div v-if="false" class="row justify-content-center fees">
                    <span v-if="!aDate.free" class="amount">{{aDate.deliveryFee | formatIntAmount }}</span>
                    <span v-if="!aDate.free" class="currency">FCFA</span>
                    <span v-if="aDate.free" class="free">0 FCFA</span>
                  </div>
                  <div v-show="aDate.today" class="row justify-content-center inprogress">
                    Livraison en cours
                  </div>
                  <div v-show="aDate.changed" class="row justify-content-center waiting">
                    En attente de validation
                  </div>
                </div>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
      </section>

      <div class="container">
        <div class="row row-payment row-action align-content-center">
          <div class="col-8 offset-2 align-content-center">
            <a @click="gotoCartContent()" class="link-pm-2">< Retour</a>
            <button type="button" @click="gotoPaiement()" class="btn btn-lg btn-primary float-right">Suivant</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="step == 'paiement'" class="row p-2 form-section">
      <div class="col-12 section-payment">
        <div class="row row-payment border-bottom">
          <div class="col-8">
            <span>Total de la commande</span>
          </div>
          <div class="col-4 text-right">
            <span class="amount">{{orderTTC | formatIntAmount}}</span>
            <span class="currency">FCFA</span>
          </div>
        </div>

        <div class="row row-payment border-bottom">
          <div class="col-8">
            <span>Valeur consigne récupérable *</span>
          </div>
          <div class="col-4 text-right">
            <span class="amount">{{glassAmount | formatIntAmount}}</span>
            <span class="currency">FCFA</span>
          </div>
        </div>

        <div class="row row-payment border-bottom">
          <div class="col-8">
            <span>Frais de livraison</span>
          </div>
          <div class="col-4 text-right">
            <span class="amount">{{orderFees | formatIntAmount}}</span>
            <span class="currency">FCFA</span>
          </div>
        </div>

        <div class="row row-payment border-bottom">
          <div class="col-8">
            <span>Solde disponible</span>
          </div>
          <div class="col-4 text-right">
            <span class="amount">{{user.balance | formatIntAmount}}</span>
            <span class="currency">FCFA</span>
          </div>
        </div>
        <div class="row row-payment row-recap">
          <div class="col-8">
            <span>Total dû</span>
          </div>
          <div class="col-4 text-right">
            <span class="amount">{{amountDue | formatIntAmount}}</span>
            <span class="currency">FCFA</span>
          </div>
        </div>

        <div class="row row-payment row-action">
          <div class="col-12 text-center" v-show="!requirePayment">
            <span class="message-success">Solde suffisant pour passer commande <i class="fas fa-check"></i> </span>
          </div>
          <div class="col-12 text-center" v-show="requirePayment">
            <div class="payment-group btn-group btn-group-toggle" data-toggle="buttons">
              <label class="btn btn-outline-success">
                <input type="radio" v-model="paymentMode" value="delivery" id="payment-delivery" autocomplete="off"> Paiement à la livraison
              </label>
              <label class="btn btn-outline-success">
                <input type="radio" v-model="paymentMode" value="om" id="payment-om" autocomplete="off"> Paiement par Orange Money
              </label>
              <label class="btn btn-outline-success">
                <input type="radio" v-model="paymentMode" value="cb" id="payment-cb" autocomplete="off"> Paiement par carte bancaire
              </label>
              <label class="btn btn-outline-success">
                <input type="radio" v-model="paymentMode" value="wave" id="payment-wave" autocomplete="off"> Paiement par WAVE
              </label>
            </div>
          </div>
        </div>

        <div class="row row-payment row-action align-content-center">
          <div class="col-8 offset-2 align-content-center">
            <a @click="gotoSelectDate()" class="link-pm-2">< Retour</a>
            <button type="button" @click="validate()" class="btn btn-lg btn-primary float-right">Lancer commande</button>
          </div>
        </div>

        <div class="row row-payment row-action align-content-center">
          <div class="col-8 offset-2 align-content-center">
            * La valeur des articles consignés rendus en bon état vous sera crédité et reduira le total dû
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import eventBus from './packs/eventBus'
import service from './packs/services'
import viewHelper from './packs/zone_helper'

export default {
  props: ["userId"],

  data: function () {
    return {
      step: 'cart-content',
      user: null,
      order: null,
      cart: null,
      boxes: [],
      likes: null,
      deliveryDates:[],
      selectedDate: null,
      todayOrder: null,
      deliveryFees: [],
      paymentMode: null,
      paymentProvider: {
        value: 'WAVE',
        label: 'WAVE'
      },
      paymentAmount: null,
      amountToPay: 0,
      amountToCharge: 0,
      startPayment: false,
      delivery_fee: 0,
      slickSettings: {
        arrows: true,
        slidesToShow: 6,
        slidesToScroll: 6,
        infinite: false
      },
      productConsignes: null
    }
  },
  async mounted() {
    this.productConsignes = await service.getConsignes()
    this.refresh()
    eventBus.$on('OrderNeedValidation', function() {
      this.refresh()
    }.bind(this))

    eventBus.$on('OrderUpdated', function() {
      this.refresh()
    }.bind(this))

    eventBus.$on('ProductDeleted', function() {
      this.refresh()
    }.bind(this))

    eventBus.$on('DateChanged', function(event) {
      this.selectedDate = event.selectedDate
      this.delivery_fee = 0
    }.bind(this))
  },

  computed: {

    pageTitle() {
      if(this.step == 'cart-content') {
        return 'VALIDATION COMMANDE'
      }
      if(this.step == 'select-date') {
        return 'SELECTION DATE LIVRAISON'
      }
      if(this.step == 'paiement') {
        return 'PAIEMENT & CONFIRMATION'
      }
    },

    isToday() {
      return this.deliveryDates.length > 0 && this.deliveryDates[0].today
    },

    cartProducts() {
      if (this.cart){
        return this.cart.order_items.filter(item => !item.panier)
      }
      return []
    },

    cartBoxes() {
      if (this.cart){
        return this.cart.order_items.filter(item => item.panier)
      }
      return []
    },

    orderProducts() {
      if (this.order){
        return this.order.order_items.filter(item => item.type === 'product')
      }
      return []
    },

    /** init boxes */
    orderBoxes() {
      if (this.order && this.boxes.length > 0){
        let orderedBoxes = []
        this.boxes.forEach(box => {
          let occurrence = this.order.order_items.find(oi => oi.type === 'box' && oi.box_id == box.id)
          if(occurrence) {
            let productBox = box.box_items.find(b => occurrence.product.id == b.productId)
            box.quantity = occurrence.quantity / productBox.quantity
            box.oneShot = occurrence.oneShot
            orderedBoxes.push(box)
          }
        });
        return orderedBoxes
      }
      return []
    },

    requirePayment() {
      return this.amountDue > 0
    },

    amountDue() {
      if (this.user.balance >= this.orderTTCIncludingGlass) return 0
      return this.orderTTCIncludingGlass - this.user.balance
    },

    cartTotal() {
      let total = 0
      this.cart.order_items.forEach(item => {
        let price = 0
        if (item.panier) {
          price = item.box.price
        }else {
          price = item.product.price
        }
        total += item.quantity * price
      });
      return total
    },

    orderTTC() {
      return this.orderTotal + this.cartTotal
    },

    orderTTCIncludingGlass() {
      return this.orderTTC + this.glassAmount
    },

    glassAmount() {
      let total = 0
      this.cart.order_items.forEach(item => {
        total += item.glass_amount
      });
      this.order.order_items.forEach(item => {
        total += item.glass_amount
      });
      return total
    },

    cartConsignes() {
      const consignes = []
      this.cart.order_items.forEach(item => {
        if (item.panier) {
          if (item.box.has_consigne) {
            item.box.box_items.forEach(it => {
              if (it.product.GlassTypeId && it.product.GlassTypeId > 0) {
                consignes[it.product.GlassTypeId] = (consignes[it.product.GlassTypeId] || 0) + it.quantity
              }
            })
          }
        } else {
          if (item.product.GlassTypeId && item.product.GlassTypeId > 0) {
            consignes[item.product.GlassTypeId] = (consignes[item.product.GlassTypeId] || 0) + item.quantity
          }
        }
      });
      this.order.order_items.forEach(item => {
        if (item.product.GlassTypeId && item.product.GlassTypeId > 0) {
          consignes[item.product.GlassTypeId] = (consignes[item.product.GlassTypeId] || 0) + item.quantity
        }
      });
      const cs = this.productConsignes.map(c => {
        c.quantity = consignes[c.id] || 0
        return c
      })
      return cs
    },

    orderTotal() {
      let total = 0
      this.order.order_items.forEach(item => {
        total += item.quantity * item.price
      });
      return total
    },

    orderFees() {
      return this.delivery_fee
    },

    emptyCart() {
      return this.orderTotal <=0 && this.cartTotal <=0
    }

  },
  methods: {

    initDates() {
      this.deliveryDates = []
      let currentDate = this.order.date
      if (!currentDate) {
        currentDate  = moment();
      } else {
        currentDate = moment(currentDate)
      }
      // if the date is after 7 days, we recommend tomorrow
      if (currentDate.isAfter(moment().add(7, 'days'))) {
        currentDate  = moment().add(1, 'days').toDate()
      }
      // if the date is inn the past, we recommend tomorrow
      else if (currentDate.isSameOrBefore(moment(), 'day')) {
        currentDate  = moment().add(1, 'days').toDate()
      }
      // if the cart was empty, we recommend tomorrow for delivery
      else if (this.orderTotal <=0) {
        currentDate  = moment().add(1, 'days').toDate()
      }
      else {
        currentDate = currentDate.toDate()
      }

      if(this.todayOrder && this.todayOrder.id > 0) {
        this.deliveryDates.push({
          index: 0,
          date: moment(this.todayOrder.date).toDate(),
          selected: false,
          changed: false,
          today: true
        })
      }

      for (let i = 1; i < 45; i++) {
        // we remove all Sundays && Saturdays
        let momentDate = moment().add(i, 'days')
        if (i === 1 && momentDate.hour() >= 23) {
          continue
        }
        let isSaturdayCustomer = (this.zone.day === 'Saturday')
        if((isSaturdayCustomer && momentDate.isoWeekday() === 6) || (!isSaturdayCustomer && momentDate.isoWeekday() !== 7)){
          let itemDate = {
            index: i,
            date: momentDate.toDate(),
            selected: false,
            changed: false,
            free: false,
            deliveryFee: viewHelper.getDayfee(this.deliveryFees, momentDate.locale('en').format('dddd'))
          }
          if(momentDate.isSame(currentDate, 'day')) {
            itemDate.selected = true
            this.selectedDate = itemDate
            if(this.deliveryDates.length > 1 && !this.deliveryDates[0].today) {
              this.slickSettings.initialSlide = this.deliveryDates.length - 2
            }
          }
          if(momentDate.isoWeekday() == viewHelper.dayToNumValue(this.zone.day)){
            itemDate.free = true
            itemDate.deliveryFee = 0
          }
          this.deliveryDates.push(itemDate)
        }
      }

    },

    async refresh() {
      this.todayOrder = await service.getTodayOrder()
      this.user = await service.getUser(this.userId)
      this.zone = this.user.zone
      this.likes = await service.getLikes()
      this.boxes = await service.getBoxes()
      this.cart = await service.getTCartContent()
      this.order = await service.getCartContent()
      this.deliveryFees = await service.getDeliveryFees()
      this.initDates()
      this.delivery_fee = 0
    },

    /**
     * Method validate
     */
    async validate(){
      try {
        if (this.requirePayment && !this.paymentMode) {
          this.$swal({
            icon: 'error',
            cancelButtonText: 'OK',
            cancelButtonColor: '#d6403f',
            title: "Oups!",
            text: "Veuillez sélectionner un mode de paiement.",
            showCancelButton: true,
            showConfirmButton: false
          });
          return;
        }
        await service.updateDeliveryDate(this.user.id, this.selectedDate.date, this.delivery_fee)
        eventBus.$emit("DeliveryDateUpdated", {})
        await service.validateCart()
        eventBus.$emit("OrderValidated", {})
        if (this.requirePayment) {
          if (this.paymentMode !== 'delivery') {
            window.location.href = '/account/payment/' + this.paymentMode
            return
          }
        }
        window.location.href = '/account'
      } catch (err) {
        if(err.response.status == 422) {
          if (err.response.data.code == 'ERR21H') {
            this.$swal({
              icon: 'warning',
              cancelButtonText: 'OK',
              cancelButtonColor: '#2A6336',
              title: "Oups!",
              text: "Vous ne pouvez pas être livré(e) ce jour car votre panier contient un produit non livrable sous 24h. Veuillez choisir une autre date ou retirer ce(s) article(s) de votre panier.",
              showCancelButton: true,
              showConfirmButton: false
            });
          }
          if (err.response.data.code == 'ERR_BLOCKED_WEEK') {
            this.$swal({
              icon: 'warning',
              cancelButtonText: 'OK',
              cancelButtonColor: '#2A6336',
              title: "🎉 Fête de la Korite ! 🎉",
              html: "<p style=\"text-align: left;\">Nous faisons une petite pause, svp choisissez une autre date et on se charge du reste.</p>",
              showCancelButton: true,
              showConfirmButton: false
            });
          }
          if (err.response.data.code == 'ERR_BLOCKED_FLOWER_MONDAY') {
            this.$swal({
              icon: 'warning',
              cancelButtonText: 'OK',
              cancelButtonColor: '#2A6336',
              title: "Oups!",
              html: "<p style=\"text-align: center;\">Vous avez des fleurs dans votre panier et elles ne sont pas livrables les lundis. Merci de choisir SVP un autre jour de livraison ou de les retirer de votre panier.</p>",
              showCancelButton: true,
              showConfirmButton: false
            });
          }
        }
      }
    },

    gotoCartContent() {
      this.step = 'cart-content'
    },

    gotoSelectDate() {
      this.step = 'select-date'
    },

    gotoPaiement() {
      this.step = 'paiement'
    },

    async updateDate(updatedDate) {
      if(updatedDate.today) {
        return false;
      }
      if(updatedDate && this.selectedDate && moment(updatedDate.date).isSame(this.selectedDate.date, 'day')) {
        return false;
      }

      if(this.selectedDate) {
        this.selectedDate.selected = false
        this.selectedDate.changed = false
      }
      this.selectedDate = updatedDate
      this.selectedDate.selected = true
      this.selectedDate.changed = true
      eventBus.$emit("DateChanged", {selectedDate: this.selectedDate});
    },

    confirmEmpty() {
      window.location.href = '/rubrique/12'
    }

  }
}
</script>

<style lang='scss' scoped>

.container-cart {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 60px;
  padding-right: 60px;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 30px;
  max-width: 960px !important;
}

.row-shopping {
  margin-top: -20px;
}

.sidebar-item {
  cursor: pointer;
}

.my-account {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
}
.account-number {
  font-family: 'ITC Souvenir Std Bold';
  font-weight: bold;
}

.delivery-date {
  font-family: Recoleta;
  font-style: normal;
  height: 230px;
  background-color: #F4F3F0;
}

.delivery-date-title {
  margin-top: 20px;
  color: #2A6336;
}
.delivery-date-title h3 {
  display: inline;
  font-family: Recoleta;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
}

.delivery-date-title h3 > span {
  font-family: Recoleta;
  font-size: 20px;
}

.day-item {
  background-color: transparent;
  height: 200px;
}

.day-item-header {
  padding: 5px 0px;
  height: 45px;
}

.day-item-header img {
  display: initial;
  height: 35px;
}

.day-item-body {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  height: 150px;
  background-color: #fff;
  margin-left: 4px;
  margin-right: 4px;
  padding-top: 25px;
  padding-bottom: 10px;
  border-radius: 5px;
}

.day {
  margin-top: 20px;
  font-family: 'Montserrat';
  font-size: 14px;
  text-transform: capitalize;
}

.month {
  margin-top: 15px;
  font-family: 'Recoleta';
  font-weight: bold;
  font-size: 14px;
  color: #2A6336;
}

.fees {
  margin-top: 10px;
}

.amount {
  font-family: 'Recoleta';
  font-weight: bold;
  font-size: 15px;
}

.free {
  font-family: Recoleta;
  font-size: 15px;
  font-weight: bold;
  color: #E97834;
}

.currency {
  padding-left: 5px;
  font-family: Recoleta;
  font-size: 14px;
}

.day-item-today {
  .day-item-body {
    background-color: #E97834;
    color: #fff;
    .month{
      color: #fff;
    }
  }
  &:hover {
    .day-item-body {
      cursor: default !important;
      background-color: #E97834 !important;
    }
  }
  .inprogress {
    margin-top: 10px;
    font-size: 12px;
  }
}

.day-item-active {
  .waiting {
    margin-top: 8px;
    font-size: 12px;
    color: #fff;
  }
}

.day-item-active .day-item-body {
  background-color: #2A6336;
}

.day-item-active .day,
.day-item-active .month,
.day-item-active .fees {
  color: #fff;
}

.day-item:hover .day-item-body {
  background-color: #f6faf4;
  cursor: pointer;
}

.day-item-active:hover .day-item-body {
  background-color: #2A6336;
}

.product-container {
  display: flex;
  justify-content: center;
  margin: 0 0 1rem;
  width: 100%;

  @media screen and (min-width: 767px) {
    margin: 0 1rem 1rem 0;
    max-width: 312px;
    width: calc(50% - 1rem);
  }
}

.accordion{
  width: 100%;
  .card {
    height: auto;
    margin-bottom: 20px;
    background: #FFFFFF;
    border: 2px solid #E97834;
    border-radius: 12px;
  }
  .card-header {
    position: relative;
    background: #FFFFFF;
    padding: .5rem .75rem;
    border-bottom: 1px solid #E97834;
    button {
      display: flex;
      align-items: center;
      &:after {
        flex-shrink: 0;
        width: 1rem;
        height: 1rem;
        margin-left: auto;
        content: "";
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-size: 1rem;
        transition: transform .2s ease-in-out;
        box-sizing: border-box;
      }
      &[aria-expanded="true"]:after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        transform: rotate(180deg);
      }
    }
  }

  .card-body {
    font-family: Montserrat;
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;

    @media screen and (min-width: 767px) {
      padding-right: 0.25rem;
    }
  }

  .card button,
  .card button:hover,
  .card button:active,
  .card button:focus {
    font-family: Recoleta;
    font-size: 1.2rem;
    font-weight: 600;
    color: #E97834;
    text-decoration: none;
    box-shadow: none;
  }
}

.accordion-glass{
  .card {
    border: 2px solid #353535;
  }
  .card-header {
    border-bottom: 1px solid #353535;
  }
  .card-header-container {
    width: 100%;
  }
  .show-more {
    position: absolute;
    right: 45px;
  }

  .card button,
  .card button:hover,
  .card button:active,
  .card button:focus {
    font-family: Recoleta;
    font-size: .9rem;
    font-weight: 400;
    color: #353535;
    text-decoration: none;
    box-shadow: none;
  }
}

.amount {
  margin-left: 10px;
  font-family: 'Recoleta';
  font-weight: bold;
}

.currency {
  font-family: 'Montserrat';
}

.row-payment {
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.row-payment > div {
  padding-left: 2px;
  padding-right: 2px;
  span {
    line-height: 40px;
  }
}

.row-recap {
  background: #F4F3F0;
}

.section-simple{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 20px 20px;
}

.section-payment{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  padding: 20px 40px;
  margin-bottom: 40px;
}

.section-title {
  text-align: center;
  color: #2A6336;
}

hr {
  background-color: #2A6336;
}

.select {
  width: 200px;
  padding: 5px;
  background-color: #fff;
  color: #2A6336;
  border-radius: 8px;
  height: 35px;
  font-family: Montserrat;
  font-size: 12px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

.order-item-container {
  display: flex;
  justify-content: center;
  margin: 0 0 1rem;
  width: 100%;

  @media screen and (min-width: 767px) {
    margin: 0 1rem 1rem 0;
    max-width: 312px;
    width: calc(50% - 1rem);
  }
}

</style>
